<template>
  <div>
    <div
      class="benefits rounded mylisting-card"
      :class="{ 'border border-primary': showMore }"
      :id="`mylisting-card-${carDetail.ListingId}`"
    >
      <div class="mylisting-card-header">
        <div v-if="carDetail.Image">
          <b-img
            :src="getImageUrl(carDetail.Image)"
            blank-color="black"
            class="rounded"
            fluid-grow
          />
        </div>
      </div>
      <div class="mylisting-card-body">
        <b-row>
          <b-col>
            <span class="text-black">
              <h4 class="d-inline-block car-name">
                {{ carDetail.Year }}
                {{ carDetail.Make }}
                {{ carDetail.Model }}
              </h4>
              <b class="car-trim">{{ carDetail.Trim }}</b>
            </span>
          </b-col>

          <b-col cols="auto" class="text-right text-primary">
            <h4 v-if="carDetail.Price" class="price">
              {{ currencyFormatter(carDetail.Price.Amount ? carDetail.Price.Amount : 0) }}
            </h4>
            <h4 v-else>&nbsp;</h4>
          </b-col>
        </b-row>

        <div class="wrapper">
          <div>
            <p class="car-details">
              <span v-if="carDetail.Transmission"> {{ carDetail.Transmission }}</span>
              <span v-if="carDetail.FuelType">
                <span class="divider-vertical"/>
                {{ carDetail.FuelType }}
                </span>
              <span v-if="carDetail.Kilometers">
                <span class="divider-vertical"/>
                {{ carDetail.Kilometers ? carDetail.Kilometers : 0 }}
              </span>
            </p>

            <!-- <p class="my-1">
                <span v-if="carDetail.BodyType">{{ carDetail.BodyType }}</span>
                <span v-if="carDetail.Drivetrain">
                  | {{ carDetail.Drivetrain }}</span
                >
              </p> -->
            <p class="car-details">
              <span>
                {{ carDetail.Country }}
              </span>
              <span v-if="carDetail.City">
                <span class="divider-vertical"/> {{ carDetail.City }}
              </span>
            </p>
            <p class="car-details">
              {{$t("dashboard.label.reference")}}: <span>{{ carDetail.ListingRef }}</span>
            </p>

            <b-alert show class="listing-alert">
              <p class="item"><span>{{$t("dashboard.label.status")}}:</span> {{ carDetail.Status }}</p>
              <p class="item">
                <span>{{$t("dashboard.label.inspection")}}:</span> {{ carDetail.InspectionStatus }}
              </p>
            </b-alert>
          </div>

          <div cols="auto" class="card-details">
            <section v-if="detailType == 'pending'">
              <p>
                {{$t("dashboard.label.listed")}}:
                <span v-if="carDetail.DateListed">
                  {{
                    carDetail.DateListed.includes("T")
                      ? carDetail.DateListed.split("T")[0]
                      : carDetail.DateListed
                  }}
                </span>
              </p>
              <p>
                {{$t("dashboard.label.reference")}}: <span>{{ carDetail.ListingRef }}</span>
              </p>
              <p>
                {{$t("dashboard.label.sold")}}:
                <span v-if="carDetail.DateOfLastActivity">
                  {{
                    carDetail.DateOfLastActivity.includes("T")
                      ? carDetail.DateOfLastActivity.split("T")[0]
                      : carDetail.DateOfLastActivity
                  }}
                </span>
              </p>
              <p>
                {{$t("dashboard.label.status")}}:
                <span class="text-primary"
                  >{{ carDetail.Status }}
                  <b-icon :id="carDetail.ListingId" icon="info-circle"/>
                </span>
              </p>
            </section>
            <section v-if="detailType == 'confirmed'">
              <p>
                {{$t("dashboard.label.listed")}}:
                <span v-if="carDetail.DateListed">{{
                  carDetail.DateListed.includes("T")
                    ? carDetail.DateListed.split("T")[0]
                    : carDetail.DateListed
                }}</span>
              </p>
              <p>
                {{$t("dashboard.label.activity")}}:
                <span v-if="carDetail.DateOfLastActivity">{{
                  carDetail.DateOfLastActivity.includes("T")
                    ? carDetail.DateOfLastActivity.split("T")[0]
                    : carDetail.DateOfLastActivity
                }}</span>
              </p>
              <p>
                {{$t("dashboard.label.status")}}:
                <span class="text-primary"
                  >{{ carDetail.Status }}
                  <b-icon :id="carDetail.ListingId" icon="info-circle" />
                  <!-- <b-tooltip
                  :target="carDetail.l_ListingId"
                  triggers="hover"
                  placement="bottom"
                  variant="dark"
                  ><span style="font-size:10px;">Status</span></b-tooltip
                >--></span
                >
              </p>
            </section>

            <section v-if="detailType == 'listings'">
              <p>
                {{$t("dashboard.label.listed")}}:
                <span v-if="carDetail.DateListed">{{
                  carDetail.DateListed.includes("T")
                    ? carDetail.DateListed.split("T")[0]
                    : carDetail.DateListed
                }}</span>
              </p>
              <p>
                {{$t("dashboard.label.daysOnline")}}: <span>{{ carDetail.NumberOfDaysOnline }}</span>
              </p>
              <p>
                {{$t("ListingProcess.plan")}}: <span>{{ carDetail.Plan }}</span>
              </p>
            </section>
          </div>

          <!-- Status List end -->

          <div class="button-wrapper">
            <!-- <span class="mini-font">
                Price
                <b-icon
                  icon="info-circle"
                  :id="carDetail.ListingId + 'price'"
                ></b-icon>
                <b-tooltip
                  :target="carDetail.ListingId + 'price'"
                  triggers="hover"
                  placement="bottom"
                  variant="dark"
                  ><span style="font-size:10px;">Price</span></b-tooltip
                >
              </span> -->
            <div>
              <b-button
                v-if="detailType == 'pending'"
                class="py-0"
                style="font-size: 11px"
                size="sm"
                variant="danger"
                pill
              >
                <b-icon icon="exclamation-circle"/>
                {{$t("dashboard.label.vehicleNoLongerAvailable")}}
              </b-button>
              <h2
                v-if="detailType == 'confirmed'"
                class="text-danger m-0 font-weight-bold"
              >
                {{$t("dashboard.label.sold")}}!
              </h2>

              <div v-if="detailType == 'listings'">
                <!-- <b-button
                    size="sm"
                    class="bg-white border-0"
                    @click="getCarInfo(carDetail.ListingId)"
                    ><b-icon icon="pencil-square" variant="primary"></b-icon
                  ></b-button>
                  | -->
                <b-button
                  size="sm"
                  class="bg-white border-0"
                  @click="$bvModal.show('confirmListingCancel' + carDetail.ListingId)"
                  ><b-icon icon="trash"/></b-button>
              </div>
            </div>
            <b-button
              v-if="showMore"
              @click="closeDetails()"
              variant="link"
              class="show-button"
            >
              {{$t("dashboard.label.ShowLess")}}<b-icon icon="arrow-up"/>
            </b-button>
            <b-button
              v-else
              @click="getCarInfo(carDetail.ListingId)"
              variant="primary"
              class="show-button"
              >{{$t("dashboard.label.ShowMore")}} <b-icon icon="arrow-down" v-show="!loading"/>
              <b-icon
                icon="arrow-clockwise"
                animation="spin"
                font-scale="1"
                v-show="loading"
              />
            </b-button>
          </div>
        </div>
      </div>

      <!-- <b-row v-if="showMore" class="py-3">
      <b-col cols="12">
        <TheReviewDetail :carInfo='carinfo' :price='carDetail.Price.Amount'/>
      </b-col> -->
      <!-- <b-col cols="12">
        <TheOverViewDetail :id="carDetail.l_ListingId" :listing="carDetail" />
      </b-col> -->
      <!-- </b-row> -->
    </div>
    <div v-if="showMore" class="review-details">
      <TheReviewDetail
        :carInfo="carinfo"
        :carDetail="carDetail"
        :price="carDetail.Price.Amount"
        :mileage="carDetail.Kilometers"
        :listingId="carDetail.ListingId"
        :getCarInfo="getCarInfo"
        :key="componentKey"
      />
      <div class="text-center">
        <b-button @click="closeDetails()" variant="link" class="px-3 closeDetailsIcon">{{$t("dashboard.label.close")}}</b-button>
      </div>
    </div>
    <b-modal
      :id="'confirmListingCancel' + carDetail.ListingId"
      title="Listing Cancellation"
      hide-footer
      centered
    >
      <template #default="{ hide }">
        <p class="m-3">{{$t("dashboard.label.cancelListingMessage")}}</p>
        <b-row>
          <b-col class="m-3">
            <b-img
              :src="getImageUrl(carDetail.Image)"
              blank-color="black"
              class="carImg"
              fluid-grow
            />
          </b-col>
          <b-col class="m-3">
            <p>{{ carDetail.Year }}</p>
            <p>{{ carDetail.Make }} {{ carDetail.Model }}</p>
            <p>{{ carDetail.Trim }}</p>
          </b-col>
        </b-row>
        <b-row class="m-2">
          <b-col>
            <b-button variant="secondary" block @click="hide()">{{$t("dashboard.label.NoForgetIt")}}</b-button></b-col>
          <b-col>
            <b-button
              variant="danger"
              block
              @click="cencalVehicle(carDetail.ListingId), hide()"
              >{{$t("dashboard.label.YesCancelIt")}}</b-button
            >
          </b-col>
        </b-row>
      </template>
    </b-modal>
    <!-- {{ carDetail }} -->
  </div>
</template>

<script>
import axios from "axios";
// import TheOverViewDetail from "@/components/global/TheOverViewDetail.vue";
import TheReviewDetail from "@/components/global/TheListingReviewCard.vue";

export default {
  props: {
    carDetail: {
      type: Object,
      required: true,
    },
    detailType: {
      type: String,
      required: true,
    },
  },
  components: {
    // TheOverViewDetail,
    TheReviewDetail,
  },
  data() {
    return {
      slide: 0,
      showMore: false,
      loading: false,
      carinfo: null,
      componentKey: 0,
    };
  },
  methods: {
    scrollListing(id= false){
      const el = document.querySelector(`#mylisting-card-${id ? id : this.carDetail.ListingId}`);
      if(el) el.scrollIntoView();
    },
    scrollListingDetails(id= false){
      const el = document.querySelector(`#mylisting-card-${id ? id : this.carDetail.ListingId}`);
      if(el && el.nextSibling) el.nextSibling.scrollIntoView();
    },
    closeDetails(){
      this.scrollListing();
      this.showMore = false;
    },
    currencyFormatter(value) {
      const sc = this.$store.getters.currencyForSeller;
      return new Intl.NumberFormat(this.$store.getters.currencyCulture, {
        style: "currency",
        currency: sc ? sc : "CAD",
        minimumFractionDigits: value % 1 != 0 ? 2 : 0,
      }).format(value);
    },
    numberFormatter(value) {
      return parseFloat(value ? value : 0).toLocaleString(
        this.$store.getters.currencyCulture,
        {
          style: "unit",
          unit: "kilometer",
        }
      );
    },
    getImageUrl(imageUrl) {
      return this.$store.getters.hostURL + imageUrl;
    },
    async getCarInfo(id) {
      const el = document.querySelector('.closeDetailsIcon');
      if(el) el.click()
      this.loading = true;
      try {
        const response = await axios.post("Listing/CarInfo", {
          ListingId: id,
        });
        this.carinfo = response.data;
        this.forceRerender();
        this.showMore = true;
        this.loading = false;
        this.$nextTick(() => {
          this.scrollListingDetails(id);
        })
      } catch (error) {
        this.loading = false;
        console.log(error.data);
      }
    },
    async cencalVehicle(id) {
      try {
        await axios.post("Listing/ListingCancel", {
          ListingId: id,
        });
        this.reload();
      } catch (error) {
        console.log(error.response);
      }
    },
    forceRerender() {
      this.componentKey += 1;
    },
    reload() {
      this.$emit("qDateFilter", 4, []);
    },
  },
  mounted() {
    this.$store.dispatch('setDashboardType', 'SellerDashboard')
  },
};
</script>

<style scoped lang="scss">
.stayBottom {
  position: relative;
  bottom: 0;
}
.mylisting-card {
  padding: 24px;
  border-radius: 4px;
  margin-bottom: 16px;
  display: flex;
  &-header {
    width: 265px;
    min-width: 265px;
    height: 221px;
    border-radius: 4px;
    overflow: hidden;
    margin-right: 24px;
    div {
      width: 100%;
      height: 100%;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  &-body {
    width: 100%;
    height: 221px;
    .car-name {
      font-size: 24px;
      font-weight: 600;
      color: #111111;
      margin-bottom: 20px;
    }
    .car-trim {
      color: #111111;
      font-size: 16px;
      font-weight: 400;
      margin-left: 10px;
      margin-bottom: 20px;
    }
    .car-details {
      font-weight: 400;
      font-size: 14px;
      color: #4b4b4b;
      display: flex;
      align-items: center;
      margin-bottom: 8px;
    }
    span {
      display: inline-flex;
      align-items: center;
    }
    .divider-vertical {
      height: 16px;
      background: #777777;
      width: 1px;
      margin: 0 8px;
      display: inline-block;
    }
    .listing-alert {
      margin-top: 20px;
      border-radius: 4px;
      background: #e6f0f9;
      padding: 16px;
      border: none;
      min-height: 88px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 0;
      width: 390px;
      .item {
        font-weight: 500;
        color: #111;
        margin-bottom: 0;
        font-size: 16px;
        span {
          color: #777777;
          font-weight: 400;
        }
      }
    }
    .price {
      font-size: 24px;
      font-weight: 600;
    }
  }
  .card-details {
    padding-left: 40px;
    border-left: 1px solid rgba(0, 0, 0, 0.125);
    margin-left: 40px;
    width: 100%;
    p {
      margin-bottom: 8px;
      font-size: 14px;
      color: #777;

      span {
        font-size: 14px;
        color: #111111;
      }
    }
  }

  .show-button {
    height: 48px;
    border-radius: 40px;
    padding: 0 16px;
    font-size: 16px;
    font-weight: 500;
    font-family: "Inter" !important;
    border: 1px solid #006ac3;
    background: white;
    color: #006ac3 !important;
    min-width: 188px;
    margin-top: 20px;
    text-decoration: none;
    &:active,
    &:hover {
      background: #006ac3;
      color: #fff !important;
    }
    svg {
      font-size: 102% !important;
      margin-left: 8px;
    }
  }
  .wrapper {
    display: flex;
  }
  .button-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
  }
}
.review-details {
  border: 1px solid #006ac3;
  padding: 40px;
  border-radius: 4px;
  background: white;
  margin-bottom: 24px;
  margin-top: 24px;
}
</style>
