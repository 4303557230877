<template>
  <div>
    <div>
      <h6 class="review-details-title">{{$t("dashboard.label.details")}}</h6>
      <ul class="review-details-features">
        <li>
          <div class="name">{{$t("carDetail.label.mileage")}}:</div>
          <div class="value">
            {{ mileage }}
            <b-link
              v-show="editable"
              class="px-3 text-primary"
              @click="openWindow('Mileage', 11, mileage)"
            >
              <b-icon icon="pencil-square" scale="0.7" />{{ $t("global.button.edit") }}
            </b-link>
          </div>
        </li>
        <li>
          <div class="name">{{$t("carDetail.label.bodyType")}}:</div>
          <div class="value">{{ bodyType ? bodyType : "Unknown" }}</div>
        </li>
        <li>
          <div class="name">{{$t("carDetail.label.fuelType")}}:</div>
          <div class="value">{{ fuelType ? fuelType : "Unknown" }}</div>
        </li>
        <li>
          <div class="name">{{$t("carDetail.label.transmission")}}:</div>
          <div class="value">{{ transmission ? transmission : "Unknown" }}</div>
        </li>
        <li>
          <div class="name">{{$t("carDetail.label.engine")}}:</div>
          <div class="value">
            {{ cylinder ? cylinder : "Unknown" }} {{$t("carDetail.label.cylinders")}} /
            {{ engine ? engine : "Unknown" }} {{$t("CarDetails.LiterSign")}}
          </div>
        </li>
        <li>
          <div class="name">{{$t("carDetail.label.drivetrain")}}:</div>
          <div class="value">{{ drivetrain ? drivetrain : "Unknown" }}</div>
        </li>
        <li>
          <div class="name">{{$t("ListingProcess.NumberOfKeys")}}:</div>
          <div class="value">
            {{ key ? key : "Unknown" }}
            <b-link
              v-show="editable"
              class="text-primary px-3"
              @click="openSelectWindow('Number of keys', 13, keys)"
              ><b-icon icon="pencil-square" scale="0.7"></b-icon
              >{{ $t("global.button.edit") }}
            </b-link>
          </div>
        </li>
        <li>
          <div class="name">{{$t("carDetail.label.exteriorColor")}}:</div>
          <div class="value">{{ exteriorColor }}</div>
        </li>
        <li>
          <div class="name">{{$t("ListingProcess.NumberOfDoors")}}:</div>
          <div class="value">{{ door ? door : "Unknown" }}</div>
        </li>
        <li>
          <div class="name">{{$t("ListingProcess.NumberOfSeats")}}:</div>
          <div class="value">{{ seat ? seat : "Unknown" }}</div>
        </li>
        <li>
          <div class="name">{{$t("ListingProcess.Styles")}}:</div>
          <div class="value">{{ style ? style : "Unknown" }}</div>
        </li>
        <li>
          <div class="name">{{$t("ListingProcess.VIN")}}:</div>
          <div class="value">{{ carInfo.VIN ? carInfo.VIN : "Unknown" }}</div>
        </li>
      </ul>
    </div>
    <div class="see-all-features">
      <b-link v-b-modal.fullFeatures>{{$t("ListingProcess.SeeFullVehicleFeatures")}}</b-link>
    </div>

    <div class="section">
      <h6 class="review-details-title">
        {{$t("ListingProcess.photos")}}
      </h6>

      <div class="my-4 uploaded-images-container">
        <div
          class="mydivouter item"
          v-for="element in images"
          :key="element.ListingTagId"
        >
          <b-link :href="host + element.Value" target="_blank" v-if="element.Installed">
            <b-img
              thumbnail
              :src="host + element.Value"
              fluid
              :alt="element.ListingTagId"
            />
            <p v-show="element.TagId === 167" class="primary-badge">
              <span class="rounded px-1">
                <b-icon icon="check-circle-fill" variant="success"/>
                {{$t("ListingProcess.PrimaryImage")}}
              </span>
            </p>
          </b-link>

          <b-btn
            variant="white"
            class="mybuttonoverlap"
            @click="$bvModal.show('confirmListingCancel' + element.ListingTagId)"
          >
            <b-icon-trash/>
          </b-btn>

          <b-modal
            :id="'confirmListingCancel' + element.ListingTagId"
            title="Do you want delete this image"
            hide-footer
            centered
          >
            <template #default="{ hide }">
              <b-img
                :src="host + element.Value"
                blank-color="black"
                class="px-5 py-3"
                fluid-grow
              />
              <b-row class="m-2">
                <b-col
                  ><b-button variant="secondary" block @click="hide()"
                    >{{$t("carDetail.label.NoKeepIt")}}</b-button
                  ></b-col
                >
                <b-col
                  ><b-button
                    variant="danger"
                    block
                    @click="deleteImages(element.ListingTagId, 16), hide()"
                    >{{$t("carDetail.label.YesDeleteIt")}}</b-button
                  ></b-col
                >
              </b-row>
            </template>
          </b-modal>
        </div>
      </div>
    </div>

    <div class="section">
      <h6 class="review-details-title">
        {{$t("ListingProcess.AskingPrice")}}
        <!-- <b-icon icon="exclamation-circle" scale="0.7"></b-icon> -->
      </h6>

      <h6 class="asking-price">
        {{$t("ListingProcess.AskingPrice")}}:
        <b>{{ currencyFormatter(price) }}</b>
        <b-btn
          v-show="editable"
          class="border-0 bg-transparent text-primary mini-font py-0"
          @click="openWindow($t('ListingProcess.AskingPrice'), null, parseInt(price))"
          ><b-icon icon="pencil-square" scale="0.7"></b-icon
          >{{ $t("global.button.edit") }}
        </b-btn>
      </h6>
      <b-alert show dismissible class="alert-box info-box">
        <span>
          {{$t("ListingProcess.priceEnterWarn")}}
        </span>
      </b-alert>
    </div>

    <div class="section">
      <h6 class="review-details-title">{{$t("ListingProcess.ContactPerson")}}</h6>
      <span class="contact-person-subtitle">
        {{$t("ListingProcess.ContactPersonDesc")}}
      </span>
      <div class="avatar-container" v-if="!editContact">
        <div class="avatar-header">
          <b-avatar class="avatar" v-if="contactinfo.FirstName && contactinfo.LastName">
            {{ contactinfo.FirstName[0] }}{{ contactinfo.LastName[0] }}</b-avatar
          >
        </div>
        <div class="avatar-header">
          <p class="username">
            {{ contactinfo.FirstName }} {{ contactinfo.LastName }}
            <b-button
              variant="transparent"
              class="border-0 text-primary p-0 btn-edit ml-4"
              @click="editContact = true"
            >
              <b-icon icon="pencil-square"></b-icon>
              {{ $t("global.button.edit") }}
            </b-button>
          </p>
          <p class="email">{{ contactinfo.Email }}</p>
          <p class="mobile">{{ contactinfo.Mobile }}</p>
        </div>
      </div>

      <b-card class="review-address-form" v-else-if="editContact">
        <div class="item">
          <label>{{$t("contactInfo.label.firstName")}}</label>
          <b-form-input
            type="text"
            v-model="firstName"
            @input="checkInput('vehicleContactFirstName', firstName)"
            class="input-pills"
          />
        </div>

        <div class="item">
          <label>{{$t("contactInfo.label.lastName")}}</label>
          <b-form-input
            type="text"
            v-model="lastName"
            @input="checkInput('vehicleContactLastName', lastName)"
            class="input-pills"
          />
        </div>

        <div class="item">
          <label>{{ $t("accountSettings.label.mobileNum") }}</label>
          <b-input-group class="row m-0 phone-wrapper">
            <VuePhoneNumberInput
              class="col p-0"
              v-model="mobile"
              :default-country-code="
                $store.getters.country.Code ? $store.getters.country.Code : 'CA'
              "
              :translations="{
                countrySelectorLabel: $t('Contact.countrySelectorLabel'),
                countrySelectorError: $t('Contact.countrySelectorError'),
                phoneNumberLabel: $t('Contact.phoneNumberLabel'),
                example: $t('Contact.example'),
              }"
              @input="
                checkInput('vehicleContactMobile', mobile),
                  checkInput(
                    'vehicleContactMobileCountryCode',
                    mobilePlayload.countryCallingCode
                  )
              "
              @update="mobilePlayload = $event"
            />
          </b-input-group>
        </div>

        <div class="item">
          <label>{{$t("contactInfo.label.emailAddress")}}</label>
          <b-form-input
            type="text"
            v-model="email"
            @input="checkInput('vehicleContactEmail', email)"
            class="input-pills"
          />
        </div>

        <b-row>
          <b-col>
            <b-btn
              pill
              variant="outline-primary"
              class="w-100"
              @click="editContact = false"
              >{{$t("global.button.cancel")}}</b-btn
            >
          </b-col>
          <b-col>
            <b-btn
              pill
              variant="primary"
              class="w-100"
              @click="(editContact = false), sendContact()"
              >{{$t("global.button.submit")}}</b-btn
            >
          </b-col>
        </b-row>
      </b-card>
    </div>

    <div class="section">
      <h6 class="review-details-title">{{$t("listing.label.contact")}}</h6>
      <span class="contact-person-subtitle">{{$t("ListingProcess.ContactPersonDesc")}} Please let us know where the car is. </span>

      <div class="avatar-container address-container" v-if="!editLocation">
        <div>
          <b-avatar class="avatar" variant="whiteBlue">
            <b-icon-geo-alt-fill variant="primary" scale="0.8" />
          </b-avatar>
        </div>
        <div class="address-summery">
          <p>
            <b class="pl-3" v-show="contactinfo.AddressLine2"
              >{{ contactinfo.AddressLine2 }} -
            </b>
            <b>{{ contactinfo.AddressLine }}</b>
            <b-button
              v-show="editable"
              variant="transparent"
              class="border-0 text-primary p-0 ml-4"
              @click="editLocation = true"
            >
              <b-icon icon="pencil-square"></b-icon>
              {{ $t("global.button.edit") }}
            </b-button>
          </p>
          <p>
            {{ contactinfo.City }}<span v-show="contactinfo.ZipCode">, {{ contactinfo.ZipCode }}</span>
          </p>
          <p>
            <span v-show="contactinfo.Province">{{ contactinfo.Province }}, </span
            >{{ contactinfo.CountryName }}
          </p>
        </div>
      </div>

      <div class="card edit-form" v-else-if="editLocation">
        <TheAddressForm
          :countryLocked="countryInfo"
          @addressChange="handleAddress"
          :key="componentKey"
        />

        <b-row>
          <b-col>
            <b-btn
              variant="outline-primary"
              class="w-100 submit-address"
              @click="(editLocation = false), forceRerender()"
              >{{ $t("global.button.cancel") }}</b-btn
            >
          </b-col>
          <b-col>
            <b-btn
              pill
              variant="primary"
              class="w-100 submit-address"
              @click="(editLocation = false), sendLocation()"
              >{{ $t("global.button.submit") }}</b-btn
            >
          </b-col>
        </b-row>
      </div>
    </div>

    <b-alert
      variant="success"
      show
      dismissible
      class="alert-box success-box flex-column align-items-start justify-content-center"
    >
      <h5>
        <b-avatar variant="success" size="1.4em"
          ><b-icon-shield-check scale="0.8"
        /></b-avatar>
        <b class="px-2 mb-4 d-inline-block">{{ $t("ListingProcess.ContactWarnTitle") }}</b>
      </h5>
      <span>
        {{ $t("ListingProcess.ContactWarnDesc") }}
      </span>
    </b-alert>
    <b-modal id="modifyValue" :title="modalTitle" hide-footer centered>
      <div class="p-5 text-center">
        <b-input-group class="m-auto" style="max-width: 300px" v-if="!inputType">
          <b-input-group-prepend class="border left-rounded">
            <country-flag
              style="max-height: 36px"
              :country="$store.getters.userCountryCode"
            />
            <span class="my-auto mr-2">{{ $store.getters.currencyForSeller }}</span>
          </b-input-group-prepend>

          <b-form-input
            type="number"
            min="0"
            class="right-rounded"
            v-model="inputValue"
            :oninput="oninput"
          />
        </b-input-group>

        <b-input-group
          class="mx-auto pl-3"
          style="max-width: 300px"
          v-else-if="inputType === 11"
        >
          <b-form-input
            type="number"
            min="1"
            max="9"
            class="button-rounded"
            v-model="inputValue"
            :oninput="oninput"
          />
        </b-input-group>
        <b-form-radio-group
          disabled
          v-show="inputType === 11"
          v-model="odometer"
          @change="changeUnit()"
          class="py-2"
        >
          <b-form-radio value="km">{{ $t("ListingProcess.Kilometers") }}</b-form-radio>
          <b-form-radio value="mi">{{ $t("ListingProcess.Miles") }}</b-form-radio>
        </b-form-radio-group>
        <b-row class="mt-3">
          <b-col>
            <b-btn
              variant="recommend"
              pill
              size="sm"
              style="width: 100px"
              @click="$bvModal.hide('modifyValue')"
              >{{ $t("global.button.cancel") }}
            </b-btn>
          </b-col>
          <b-col>
            <b-btn
              v-if="inputType === 11"
              variant="primary"
              pill
              size="sm"
              style="width: 100px"
              @click="setListing(), $bvModal.hide('modifyValue')"
              >{{ $t("global.button.update") }}
            </b-btn>
            <b-btn
              v-else
              variant="primary"
              pill
              size="sm"
              style="width: 100px"
              @click="sendPrice(inputValue), $bvModal.hide('modifyValue')"
              >{{ $t("global.button.update") }}
            </b-btn>
          </b-col>
        </b-row>
      </div>
    </b-modal>

    <b-modal id="selectValues" :title="modalTitle" hide-footer centered>
      <div class="p-5 text-center">
        <div class="pb-4">
          <b-form-checkbox
            v-model="inputValue"
            button
            v-for="l in selectList"
            :key="l.Order"
            :value="l.TagValueId"
            class="mx-1"
            button-variant="outline-primary"
          >
            <span class="px-2">{{ l.Value }}</span>
          </b-form-checkbox>
        </div>

        <b-row class="mt-3">
          <b-col>
            <b-btn
              variant="recommend"
              pill
              size="sm"
              style="width: 100px"
              @click="$bvModal.hide('selectValues')"
              >{{ $t("global.button.cancel") }}
            </b-btn>
          </b-col>
          <b-col>
            <b-btn
              variant="primary"
              pill
              size="sm"
              style="width: 100px"
              @click="setListing(), $bvModal.hide('selectValues')"
              >{{ $t("global.button.update") }}
            </b-btn>
          </b-col>
        </b-row>
      </div>
    </b-modal>

    <b-modal id="fullFeatures" size="lg" scrollable hide-footer>
      <template #modal-title>
        <h3 class="modal-title">{{ $t("Overview.AllFeatures") }}</h3></template
      >
      <div class="modal-carname">
        <h3 class="car-make">
          {{ carDetail.Year }}
          {{ carDetail.Make }}
          {{ carDetail.Model }}
        </h3>
        <h3 class="car-mode">
          {{ carDetail.Trim }}
        </h3>
      </div>

      <div class="modal-all-features">
        <div v-for="(sf, key) in allOptions" :key="key">
          <template v-if="sf.Tags && sf.Tags.length > 0 && sf.Tags.some(o => o.TagValues[0] && o.TagValues[0].Installed === true)">
            <h4
              class="pb-2 feature-part-title"
              v-show="countContents(sf.Tags, sf.TagCategories) > 0"
            >
              {{ sf.Name }}
            </h4>
            <ul>
              <template
                v-for="(sft, key) in sf.Tags.filter(
                  (item) => item.TagValues[0] && item.TagValues[0].Installed === true
                )"
              >
                <li
                  :key="key"
                  class="feature-item"
                  v-if="sft.TagValues[0].Installed"
                >
                  <span>
                    {{ sft.TagName }}
                  </span>
                </li>
              </template>
            </ul>
            <ul
              v-for="(sftc, key) in sf.TagCategories"
              :key="'sftc_' + key"
              class="feature-item"
            >
              <li
                v-for="(sftct, key) in sftc.Tags"
                :key="'sftct_' + key"
                class="feature-item"
              >
                <span
                  v-show="sftct.IsRequired && sftct.TagValues[0].Installed"
                  class="mb-1"
                >
                  {{ sftct.TagName }}
                </span>
              </li>
            </ul>
          </template>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  components: {
    VuePhoneNumberInput,
    TheAddressForm: () => import("@/components/global/TheAddressForm.vue"),
    TheUpload: () => import("@/components/global/TheUploadComponent"),
  },
  data() {
    return {
      editable: true,
      modalTitle: null,
      inputValue: null,
      selectList: null,
      inputType: null,
      storeName: null,
      odometer: this.$store.getters.mileageUnit,
      allOptions: [],
      keys: null,
      key: null,
      images: null,
      host:
        this.$store.getters.hostURL + "sitemedia/Images/Listings/" + this.listingId + "/",
      door: null,
      seat: null,
      bodyType: null,
      fuelType: null,
      transmission: null,
      engine: null,
      cylinder: null,
      drivetrain: null,
      style: null,

      firstName: null,
      lastName: null,
      email: null,
      mobile: null,
      mobileCountryCode: null,
      address1: null,
      address2: null,
      city: null,
      province: null,
      zipcode: null,
      componentKey: 0,
      editContact: false,
      editLocation: false,
    };
  },
  props: {
    carInfo: {
      type: Array,
      required: true,
    },
    price: {
      type: Number,
      require: true,
    },
    mileage: {
      type: Number,
      require: true,
    },
    listingId: {
      type: Number,
      require: true,
    },
    getCarInfo: {
      type: Function,
    },
    carDetail: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["vehicleName", "vehicleTrim"]),
    tagCategories() {
      return this.carInfo.TagCategories ? this.carInfo.TagCategories : [];
    },
    contactinfo() {
      return this.carInfo.Contact ? this.carInfo.Contact : {};
    },
    exteriorColor(){
      try{
        return this.carInfo.TagCategories.find(o => o.Id == 3).Tags.find(o => o.TagId == 8).TagValues.find(o => o.Installed == true).Value
      }catch (error){
        return null
      }
    },
    oninput() {
      if (this.inputType === 11) return "this.value = Math.abs(this.value)";
      if (this.inputType === 2)
        return "if(this.value < 1) this.value = 1; if(this.value > 9) this.value = null;";
      else
        return "this.value = Math.abs(this.value); if(this.value === 0) this.value = null;";
    },
    countryInfo() {
      return {
        Name: "Canada",
        Code: "CA",
      };
    },
    cardData() {
      if (this.$store.getters.listing) {
        return this.$store.getters.listing.Cardsdata;
      } else {
        return null;
      }
    },
  },
  methods: {
    openWindow(title, id, value) {
      this.modalTitle = title;
      this.inputType = id;
      this.inputValue = value;
      this.$bvModal.show("modifyValue");
    },
    openSelectWindow(title, id, arr) {
      this.modalTitle = title;
      this.selectList = arr;
      this.inputType = id;
      arr.forEach((element) => {
        if (element.Installed) {
          this.inputValue = element.TagValueId;
        }
      });

      this.$bvModal.show("selectValues");
    },
    saveInput(item, value) {
      this.$store.dispatch(item, value);
    },
    changeUnit() {
      this.$store.dispatch("mileageUnit", this.odometer);
    },
    numberFormatter(value) {
      return parseFloat(value ? value : 0).toLocaleString(
        this.$store.getters.currencyCulture,
        {
          style: "unit",
          unit: "kilometer",
        }
      );
    },
    currencyFormatter(value) {
      return new Intl.NumberFormat(this.$store.getters.currencyCulture, {
        style: "currency",
        currency: this.$store.getters.user.Currency
          ? this.$store.getters.user.Currency.Code
          : this.$store.getters.currency,
        minimumFractionDigits: value % 1 != 0 ? 2 : 0,
      }).format(value);
    },
    getDatas() {
      this.tagCategories.forEach((element) => {
        if (element.Id === 1) {
          element.Tags.forEach((el) => {
            // if (el.TagId === 11) {
            //   el.TagValues.forEach(e => {
            //     if (e.Installed) {
            //       this.mileage = e.Value;
            //     }
            //   });
            // }
            if (el.TagId === 5) {
              // this.doors = el.TagValues;
              el.TagValues.forEach((e) => {
                if (e.Installed) {
                  this.style = e.Value;
                }
              });
            }
            if (el.TagId === 6) {
              // this.doors = el.TagValues;
              el.TagValues.forEach((e) => {
                if (e.Installed) {
                  this.door = e.Value;
                }
              });
            }
            if (el.TagId === 7) {
              // this.seats = el.TagValues;
              el.TagValues.forEach((e) => {
                if (e.Installed) {
                  this.seat = e.Value;
                }
              });
            }
          });
        }
        if (element.Id === 3) {
          element.Tags.forEach((el) => {
            if (el.TagId === 13) {
              this.keys = el.TagValues;
              el.TagValues.forEach((e) => {
                if (e.Installed) {
                  this.key = e.Value;
                }
              });
            }
            if (el.TagId === 9) {
              el.TagValues.forEach((e) => {
                if (e.Installed) {
                  this.bodyType = e.Value;
                }
              });
            }
            if (el.TagId === 18) {
              el.TagValues.forEach((e) => {
                if (e.Installed) {
                  this.fuelType = e.Value;
                }
              });
            }
            if (el.TagId === 21) {
              el.TagValues.forEach((e) => {
                if (e.Installed) {
                  this.transmission = e.Value;
                }
              });
            }
            if (el.TagId === 25) {
              el.TagValues.forEach((e) => {
                if (e.Installed) {
                  this.engine = e.Value;
                }
              });
            }
            if (el.TagId === 20) {
              el.TagValues.forEach((e) => {
                if (e.Installed) {
                  this.drivetrain = e.Value;
                }
              });
            }
            if (el.TagId === 24) {
              el.TagValues.forEach((e) => {
                if (e.Installed) {
                  this.cylinder = e.Value;
                }
              });
            }

          });
        }
        if (element.Id === 4) {
          this.allOptions = element.TagCategories;
        }
        if (element.Id === 100) {
          element.TagCategories.forEach((eCat) => {
            eCat.Tags.forEach((el) => {
              if (el.TagId === 16) {
                if (el.TagValues.length) this.images = el.TagValues;
              }
              if (el.TagId === 167) {
                if (el.TagValues && el.TagValues[0]) this.images.push({...el.TagValues[0],TagId: 167});
              }
            })
          })
        }
      });
    },
    setListing() {
      let v = this.inputValue.toString(),
        tag = [
          {
            TagId: this.inputType,
            Value: v,
          },
        ];
      this.sendListingChange(tag);
    },
    async sendListingChange(tag) {
      try {
        await axios.post("Listing/SetListing", {
          ListingId: this.listingId,
          Tags: tag,
        });
        if (this.inputType === 11) {
          this.$store.dispatch("getMyListingsList", {
            status: ["-1"],
            date: "-1",
          });
        }
        this.getCarInfo(this.listingId);
      } catch (error) {
        console.log(error);
      }
    },
    handleAddress(address) {
      this.address1 = address.streetAddress;
      this.address2 = address.apt;
      this.city = address.city;
      this.province = address.region;
      this.zipcode = address.postal;
    },
    async sendContact() {
      try {
        await axios.post("Listing/SetListing", {
          ListingId: this.listingId,
          Contact: {
            FirstName: this.firstName,
            LastName: this.lastName,
            Email: this.email,
            Mobile: this.mobile,
          },
        });
        this.getCarInfo(this.listingId);
      } catch (error) {
        console.log(error.response.data);
      }
    },
    async sendLocation() {
      try {
        await axios.post("Listing/SetListing", {
          ListingId: this.listingId,
          Contact: {
            CountryCode: this.$store.getters.userCountryCode,
            Province: this.province,
            City: this.city,
            AddressLine: this.address1,
            AddressLine2: this.address2,
            Zipcode: this.zipcode,
          },
        });
        this.getCarInfo(this.listingId);
      } catch (error) {
        console.log(error.response.data);
      }
    },
    async sendPrice(value) {
      try {
        await axios.post("Listing/SetListing", {
          ListingId: this.listingId,
          Amount: value,
          CurrencyCode: "CAD",
        });
        this.$store.dispatch("getMyListingsList", {
          status: ["-1"],
          date: "-1",
        });
      } catch (error) {
        console.log(error.response.data);
      }
    },
    forceRerender() {
      this.componentKey += 1;
    },
    async deleteImages(listingTagId, tagId) {
      try {
        await axios.post("Listing/SetListing", {
          ListingId: this.listingId,
          Tags: [
            {
              ListingTagId: listingTagId,
              TagId: tagId,
              Remove: true,
            },
          ],
        });
        this.images = this.images.filter((e) => e.ListingTagId !== listingTagId);
      } catch (error) {
        console.log(error);
      }
    },
    countContents(tags, tagCategories) {
      tags = tags.filter((el) => el.IsRequired && el.TagValues[0].Installed);
      return tags.length + tagCategories.length;
    },
  },
  beforeMount() {
    this.getDatas();
  },
};
</script>

<style scoped lang="scss">
.mydivouter {
  position: relative;
}
.mydivoverlap {
  position: relative;
  z-index: 1;
}
.mybuttonoverlap {
  position: absolute;
  z-index: 2;
  top: 10px;
  right: 25px;
  display: none;
  padding: 5px 8px;
  border-radius: 25px;
}
.mydivouter:hover .mybuttonoverlap {
  display: block;
}
.review-details-title {
  color: #111111;
  font-weight: 600;
  padding-bottom: 5px;
  border-bottom: 1px solid #d0d0d0;
  font-size: 20px;
  margin-bottom: 0;
}
.review-details-features {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 245px;
  flex-wrap: wrap;
  margin-top: 12px;
  margin-bottom: 0;
  li {
    display: flex;
    margin-bottom: 16px;
  }
  .name {
    color: #111111;
    min-width: 180px;
  }
  .value {
    color: #111111;
    font-weight: 500;
  }
}
.see-all-features {
  a {
    font-weight: 500;
  }
}
.section {
  margin-top: 64px;
}

.uploaded-images-container {
  display: flex;
  flex-wrap: wrap;
  column-gap: 16px;
  row-gap: 16px;
  @media (max-width: 991px) {
    column-gap: 16px;
  }
  @media (max-width: 575px) {
    column-gap: 8px;
    row-gap: 8px;
  }
  .item {
    width: calc((100% / 5) - 26px) !important;
    height: 173px !important;
    position: relative;
    @media (max-width: 991px) {
      width: calc((100% / 4) - 12px) !important;
    }
    @media (max-width: 767px) {
      height: 130px !important;
    }
    @media (max-width: 575px) {
      width: calc((100% / 4) - 6px) !important;
      height: 90px !important;
    }
    @media (max-width: 480px) {
      height: 65px !important;
    }
    img {
      width: 100%;
      height: 100%;
      border: none;
      padding: 0;
    }
  }
  .primary-badge {
    position: absolute;
    bottom: 8px;
    right: 8px;
    background: #4bb543;
    color: white;
    border-radius: 4px;
    font-size: 12px;
    width: 80px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    svg {
      fill: white;
      margin-right: 2px;
    }
  }
}
.asking-price {
  color: #232323;
  font-weight: 400;
  padding-top: 32px;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  b {
    font-weight: 600;
    margin-left: 5px;
  }
}
.alert-box {
  border: none;
  display: flex;
  align-items: center;
  min-height: 30px;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  padding-left: 40px !important;
  min-height: 72px;
  font-size: 16px;
  color: #111111;
  line-height: 24px;
  margin-top: 32px;
  b {
    font-weight: 600;
    color: #111;
    font-family: "Inter", sans-serif;
    line-height: normal;
  }
  p {
    margin-bottom: 0 !important;
    margin-top: 12px;
  }
  &.success-box {
    background: #dbf0d9;
    min-height: 112px;
    margin-bottom: 64px;
  }
  &.info-box {
    background: #e6f0f9;
    color: #111111;
  }
  h5 {
    font-weight: 500;
    font-size: 16px;
    color: #4bb543;
    margin-bottom: 0;
  }
  i {
    margin-right: 8px;
    color: #4bb543;
    font-size: 25px;
  }
}
.contact-person-subtitle {
  font-size: 16px;
  color: #4b4b4b;
  padding-top: 16px;
  display: block;
  margin-bottom: 40px;
}
.edit-form {
  margin-top: 40px;
  width: 455px;
  border-color: #dbdbdb;
  margin-right: auto;
  margin-left: auto;
  padding: 24px;
  border-radius: 10px;
  .card-body {
    padding: 0;
    margin-bottom: 24px;
  }
  input {
    height: 48px;
  }
}
.avatar-container {
  display: flex;
  margin-top: 40px;
}
.address-container {
  margin-bottom: 64px;
}
.avatar {
  width: 97px;
  height: 97px;
  background: #cce1f3;
  margin-right: 16px;
  font-size: 28px;
  font-family: "Poppins", sans-serif !important;
  font-weight: 600;
  text-transform: uppercase;
  color: #006ac3;
}
.username {
  color: #111111;
  font-size: 18px;
  margin-bottom: 2px;
  font-weight: 500;
  text-transform: capitalize;
}
.email {
  color: #006ac3;
  margin-bottom: 8px;
  font-size: 18px;
  text-transform: lowercase;
}
.mobile {
  font-size: 18px;
  color: #111111;
  margin-bottom: 0;
}
.btn-edit {
  font-size: 16px;
  font-weight: 500 !important;
  font-family: "Inter" !important;
}
.address-summery {
  p {
    margin-bottom: 3px;
    font-size: 18px;
    color: #111111;
  }
  b {
    font-size: 18px;
    font-weight: 500;
    color: #111111;
  }
}
</style>

<style lang="scss">
.alert {
  .close {
    position: absolute;
    right: 40px !important;
    z-index: 2;
    padding: 0.75rem 1.25rem;
    top: 50% !important;
    transform: translateY(-50%);
    font-size: 40px;
    font-weight: 200;
    color: #111;
    opacity: 1;
    padding: 0 !important;
  }
}
.review-address-form {
  width: 400px;
  margin: auto;
  .section-title {
    font-weight: 600;
    font-size: 20px;
    font-family: "Inter", sans-serif !important;
    color: #111111;
    margin-bottom: 0;
  }
  .card-body {
    padding: 24px;
  }
  input {
    height: 48px;
    border-color: #d0d0d0 !important;
    box-shadow: none !important;
  }
  .item {
    margin-bottom: 24px;
  }
  .input-tel__input {
    border-left: 0 !important;
    &::placeholder {
      color: #acacac !important;
      font-size: 12px;
      font-family: "Inter", sans-serif !important;
    }
  }
  .country-selector {
    position: relative;
    z-index: 2 !important;
    &:after {
      content: "";
      position: absolute;
      right: 0;
      height: 33px;
      background: #d0d0d0;
      width: 1px;
      top: 50%;
      transform: translateY(-50%);
    }
    svg {
      display: none !important;
    }
    &__label {
      display: none;
    }
    &__input {
      padding: 0 0 0 12px !important;
      border-right: 0 !important;
      font-size: 14px;
      color: #777 !important;
      font-family: "Inter", sans-serif !important;
    }
  }
  .btn {
    height: 48px;
    font-size: 16px;
    font-family: "Inter", sans-serif !important;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    &.btn-secondary {
      border: 1px solid #006ac3;
      color: #006ac3;
      background: none;
    }
    &:hover {
      background: #00559d !important;
      color: white !important;
    }
  }
  .phone-wrapper {
    border-radius: 40px;
    input {
      height: 48px !important;
    }
    .input-group-append {
      height: 48px !important;
      button {
        border-left: 0 !important;
      }
    }
    .country-selector {
      height: 100%;
    }
    .ReservationWizard .country-selector__country-flag {
      top: 19px !important;
    }
  }
  .country-selector {
    .country-selector__input {
      padding-left: 40px !important;
      border-radius: 45px 0 0 45px !important;
    }
  }
  .input-tel {
    input {
      border-radius: 0 45px 45px 0 !important;
    }
  }
}
.edit-form {
  margin-top: 40px;
  width: 455px;
  border-color: #dbdbdb;
  margin-right: auto;
  margin-left: auto;
  padding: 24px;
  border-radius: 10px;
  .card-body {
    padding: 0;
    margin-bottom: 24px;
  }
  input {
    height: 48px;
  }
  .btn {
    height: 48px;
    font-size: 16px;
    font-family: "Inter", sans-serif !important;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px !important;
    &.btn-secondary {
      border: 1px solid #006ac3;
      color: #006ac3;
      background: none;
    }
    &:hover {
      background: #00559d !important;
      color: white !important;
    }
  }
}
#fullFeatures {
  .modal-header {
    background: #006ac3 !important;
    height: 58px;
    position: relative;
  }
  .modal-body {
    padding: 0;
  }
  .modal-title {
    font-weight: 600;
    color: white;
    font-size: 20px;
    text-transform: capitalize;
  }
  .modal-content {
    border-radius: 4px;
    overflow: hidden;
    border: none;
  }
  .close {
    top: 15px;
    opacity: 1;
    color: white;
    padding: 0;
    font-size: 40px;
    position: absolute;
    right: 30px;
    height: 100%;
    padding-bottom: 10px;
    font-weight: normal;
  }
  .car-make {
    font-weight: 600;
    font-size: 28px;
    line-height: 40px;
    margin-bottom: 0;
    @media (max-width: 575px) {
      font-size: 20px;
    }
  }
  .car-mode {
    font-weight: normal;
    font-size: 24px;
    color: #404040;
    margin-bottom: 0;
    line-height: normal;
  }
  .modal-carname {
    border-bottom: 1px solid #cdcdcd;
    padding: 24px;
    @media (max-width: 575px) {
      padding: 16px;
    }
  }
  .modal-all-features {
    padding: 20px 24px;
    @media (max-width: 575px) {
      padding: 16px 16px;
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      list-style: none;
    }
    li {
      width: 50%;
      @media (max-width: 575px) {
        width: 100%;
      }
    }
  }
  li.feature-item {
    min-height: auto;
    margin-bottom: 16px;
    position: relative;
    padding: 0 16px 0 40px;
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: normal;
    &:before {
      content: "";
      position: absolute;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #232323;
      left: 23px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .feature-part-title {
    font-size: 16px;
    font-weight: 500 !important;
    font-family: "Inter", sans-serif !important;
    color: #111;
    @media (max-width: 575px) {
      font-size: 18px !important;
    }
  }
}
.review-details {
  .btn-link {
    font-size: 20px;
    font-weight: 600;
    padding-top: 0;
    padding-bottom: 0;
  }
}
.btn-group-toggle{
  .btn.active, .btn:hover{
    color: #fff !important;
  }
}
</style>
